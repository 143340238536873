import ErrorHandler from "@/components/error-handler";
import { Spinner } from "@/components/ui/spinner";
import { getWorksheet } from "@/lib/api";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import WorksheetDetails from "@/components/worksheet-details";
import { useEffect, useState } from "react";

export const Route = createFileRoute(
  "/_auth/operations/worksheet-sets/worksheet/$worksheetId"
)({
  component: () => <WorksheetPreview />,
});

function WorksheetPreview() {
  const { worksheetId } = Route.useParams();

  const [pageImageUrls, setPageImageUrls] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);

  const { data, error, isLoading } = useQuery({
    queryKey: ["get-worksheet", worksheetId],
    queryFn: () => getWorksheet(worksheetId),
  });

  useEffect(() => {
    const calcTotalPages =
      (data?.worksheet?.bookEndPage || 0) -
      (data?.worksheet?.bookStartPage || 0) +
      1;

    setTotalPages(calcTotalPages);

    const urls = Array.from(
      { length: calcTotalPages },
      (_, i) =>
        `https://edukita-math.s3.ap-southeast-1.amazonaws.com/images/book_${data?.worksheet?.subunit?.unit?.book?.id}/${data?.worksheet?.bookOrder}-page-${i + 1}.png`
    );
    setPageImageUrls(urls);

    if (window) {
      window.scrollTo(0, 0);
    }
  }, [data]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div>
      {data?.worksheet && (
        <WorksheetDetails
          worksheetName={data.worksheet!.name}
          unitName={data.worksheet!.subunit!.unit!.name}
          subunitName={data.worksheet!.subunit!.name}
          totalPageCount={totalPages}
          bookOrder={data.worksheet!.bookOrder}
          edukitaLevelName={data.worksheet!.subunit!.unit!.edukitaLevel!.name}
        />
      )}
      <ScrollArea className="w-96 whitespace-nowrap rounded-md border">
        <div className="flex w-max space-x-4 p-4">
          {pageImageUrls.map((url, index) => (
            <figure key={index} className="shrink-0">
              <div className="overflow-hidden rounded-md">
                <img
                  src={url}
                  key={index}
                  alt={`Page ${index + 1}`}
                  className="h-96 m-auto"
                />
              </div>
              <figcaption className="pt-2 text-xs text-muted-foreground">
                Page{" "}
                <span className="font-semibold text-foreground">
                  {index + 1}
                </span>
              </figcaption>
            </figure>
          ))}
        </div>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </div>
  );
}
