import {
  integer,
  text,
  pgTable,
  serial,
  boolean,
  json,
  timestamp,
  pgEnum,
} from "drizzle-orm/pg-core";
import { relations } from "drizzle-orm";
import { createInsertSchema, createSelectSchema } from "drizzle-zod";

const enumToPgEnum = <T extends Record<string, any>>(
  myEnum: T
): [T[keyof T], ...T[keyof T][]] => {
  return Object.values(myEnum).map((value: any) => `${value}`) as any;
};

export enum AnnotationRole {
  STUDENT = "student",
  PARENT = "parent",
  TEACHER = "teacher",
  OPS = "ops",
}
export const annotationRoleTypeEnum = pgEnum(
  "annotation_role",
  enumToPgEnum(AnnotationRole)
);

// Gaudia Level
export const gaudiaLevel = pgTable("gaudia_level", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
});

export const gaudiaLevelRelations = relations(gaudiaLevel, ({ many }) => ({
  edukitaLevels: many(edukitaLevel),
  books: many(book),
}));

export const insertGaudiaLevelSchema = createInsertSchema(gaudiaLevel);
export const selectGaudiaLevelSchema = createSelectSchema(gaudiaLevel);

// Edukita Level
export const edukitaLevel = pgTable("edukita_level", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  gaudiaLevelId: integer("gaudia_level_id").references(() => gaudiaLevel.id),
});

export const edukitaLevelRelations = relations(edukitaLevel, ({ one }) => ({
  gaudiaLevel: one(gaudiaLevel, {
    fields: [edukitaLevel.gaudiaLevelId],
    references: [gaudiaLevel.id],
  }),
}));

export const insertEdukitaLevelSchema = createInsertSchema(edukitaLevel);
export const selectEdukitaLevelSchema = createSelectSchema(edukitaLevel);

// Book
export const book = pgTable("book", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  gaudiaLevelId: integer("gaudia_level_id").references(() => gaudiaLevel.id),
});

export const bookRelations = relations(book, ({ one, many }) => ({
  gaudiaLevel: one(gaudiaLevel, {
    fields: [book.gaudiaLevelId],
    references: [gaudiaLevel.id],
  }),
  units: many(unit),
}));

export const insertBookSchema = createInsertSchema(book);
export const selectBookSchema = createSelectSchema(book);

// Unit
export const unit = pgTable("unit", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  bookId: integer("book_id").references(() => book.id),
  edukitaLevelId: integer("edukita_level_id").references(() => edukitaLevel.id),
});

export const unitRelations = relations(unit, ({ one, many }) => ({
  book: one(book, {
    fields: [unit.bookId],
    references: [book.id],
  }),
  edukitaLevel: one(edukitaLevel, {
    fields: [unit.edukitaLevelId],
    references: [edukitaLevel.id],
  }),
  subunits: many(subunit),
}));

export const insertUnitSchema = createInsertSchema(unit);
export const selectUnitSchema = createSelectSchema(unit);

// Subunit
export const subunit = pgTable("subunit", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  unitId: integer("unit_id").references(() => unit.id),
  order: integer("order").notNull(),
});

export const subunitRelations = relations(subunit, ({ one, many }) => ({
  unit: one(unit, {
    fields: [subunit.unitId],
    references: [unit.id],
  }),
  worksheets: many(worksheet),
}));

export const insertSubunitSchema = createInsertSchema(subunit);
export const selectSubunitSchema = createSelectSchema(subunit);

// Marking Session Override Table
export const markingSessionOverride = pgTable("marking_session_override", {
  id: serial("id").primaryKey(),
  markingSessionId: integer("marking_session_id").notNull(),
  worksheetSetVersionId: integer("worksheet_set_version_id").references(
    () => worksheetSetVersion.id
  ),
  worksheetVersionId: integer("worksheet_version_id").references(
    () => worksheetVersion.id
  ),
  overrideStatus: text("override_status").notNull(), // pass or fail
  remarks: text("remarks"),
  createdAt: timestamp("created_at", { withTimezone: false, mode: "date" })
    .notNull()
    .defaultNow(),
  updatedAt: timestamp("updated_at", { withTimezone: false, mode: "date" })
    .notNull()
    .defaultNow(),
});

// Relations for  Markinsg Session Override
export const markingSessionOverrideRelations = relations(
  markingSessionOverride,
  ({ one }) => ({
    // Marking session relationship
    markingSession: one(markingSession, {
      fields: [markingSessionOverride.markingSessionId],
      references: [markingSession.id],
    }),
    // Worksheet version relationship
    worksheetVersion: one(worksheetVersion, {
      fields: [markingSessionOverride.worksheetVersionId],
      references: [worksheetVersion.id],
    }),
    // Worksheet set version relationship
    worksheetSetVersion: one(worksheetSetVersion, {
      fields: [markingSessionOverride.worksheetSetVersionId],
      references: [worksheetSetVersion.id],
    }),
  })
);

export const insertMarkingSessionOverrideSchema = createInsertSchema(
  markingSessionOverride
);
export const selectMarkingSessionOverrideSchema = createSelectSchema(
  markingSessionOverride
);

// Worksheet
export const worksheet = pgTable("worksheet", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  textIdentifier: text("text_identifier").notNull(),
  description: text("description"),
  bookOrder: integer("book_order").notNull(),
  subunitId: integer("subunit_id").references(() => subunit.id),
  ageRangeStart: integer("start_age_range").notNull(),
  ageRangeEnd: integer("end_age_range").notNull(),
  schoolGradeStart: text("school_grade_start").notNull(),
  schoolGradeEnd: text("school_grade_end").notNull(),
});

export const worksheetRelations = relations(worksheet, ({ one, many }) => ({
  subunit: one(subunit, {
    fields: [worksheet.subunitId],
    references: [subunit.id],
  }),
}));

// Worksheet Version
export const worksheetVersion = pgTable("worksheet_version", {
  id: serial("id").primaryKey(),
  worksheetId: integer("worksheet_id").references(() => worksheet.id),
  version: integer("version").notNull(),
  createdAt: timestamp("created_at", { withTimezone: false, mode: "date" })
    .notNull()
    .defaultNow(),
});

export const worksheetVersionRelations = relations(
  worksheetVersion,
  ({ one, many }) => ({
    worksheet: one(worksheet, {
      fields: [worksheetVersion.worksheetId],
      references: [worksheet.id],
    }),
    worksheetSetVersionItems: many(worksheetSetVersionItem),
    worksheetVersionItems: many(worksheetVersionItem),
  })
);

export const insertWorksheetSchema = createInsertSchema(worksheet);
export const selectWorksheetSchema = createSelectSchema(worksheet);

// Worksheet Version Item
export const worksheetVersionItem = pgTable("worksheet_version_item", {
  id: serial("id").primaryKey(),
  worksheetVersionId: integer("worksheet_version_id").references(
    () => worksheetVersion.id
  ),
  worksheetPageVersionId: integer("worksheet_page_version_id").references(
    () => worksheetPageVersion.id
  ),
});

export const worksheetVersionItemRelations = relations(
  worksheetVersionItem,
  ({ one, many }) => ({
    worksheetPageVersion: one(worksheetPageVersion, {
      fields: [worksheetVersionItem.worksheetPageVersionId],
      references: [worksheetPageVersion.id],
    }),
    worksheetVersion: one(worksheetVersion, {
      fields: [worksheetVersionItem.worksheetVersionId],
      references: [worksheetVersion.id],
    }),
  })
);

export const insertWorksheetVersionItemSchema =
  createInsertSchema(worksheetVersionItem);
export const selectWorksheetVersionItemSchema =
  createSelectSchema(worksheetVersionItem);

// Worksheet Set
export const worksheetSet = pgTable("worksheet_set", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  order: integer("order").notNull(),
  description: text("description").notNull(),
  gaudiaLevelId: integer("gaudia_level_id").notNull(),
});

export const worksheetSetRelations = relations(worksheetSet, ({ many }) => ({
  versions: many(worksheetSetVersion),
}));

export const insertWorksheetSetSchema = createInsertSchema(worksheetSet);
export const selectWorksheetSetSchema = createSelectSchema(worksheetSet);

// Worksheet Set Version
export const worksheetSetVersion = pgTable("worksheet_set_version", {
  id: serial("id").primaryKey(),
  worksheetSetId: integer("worksheet_set_id").references(() => worksheetSet.id),
  version: integer("version").notNull(),
  createdAt: timestamp("created_at", { withTimezone: false, mode: "date" })
    .notNull()
    .defaultNow(),
});

export const worksheetSetVersionRelations = relations(
  worksheetSetVersion,
  ({ one, many }) => ({
    worksheetSet: one(worksheetSet, {
      fields: [worksheetSetVersion.worksheetSetId],
      references: [worksheetSet.id],
    }),
    assignments: many(worksheetSetAssignment),
    worksheetSetVersionItems: many(worksheetSetVersionItem),
  })
);

// Worksheet Page
export const worksheetPage = pgTable("worksheet_page", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  pageNumber: integer("page_number").notNull(),
  worksheetId: integer("worksheet_id").references(() => worksheet.id),
});

export const worksheetPageRelations = relations(
  worksheetPage,
  ({ one, many }) => ({
    worksheet: one(worksheet, {
      fields: [worksheetPage.worksheetId],
      references: [worksheet.id],
    }),
    versions: many(worksheetPageVersion),
  })
);

// Worksheet Page Version
export const worksheetPageVersion = pgTable("worksheet_page_version", {
  id: serial("id").primaryKey(),
  worksheetPageId: integer("worksheet_page_id").references(
    () => worksheetPage.id
  ),
  version: integer("version").notNull(),
  filename: text("filename").notNull(),
});

// Relations for Worksheet Page Version
export const worksheetPageVersionRelations = relations(
  worksheetPageVersion,
  ({ one, many }) => ({
    worksheetPage: one(worksheetPage, {
      fields: [worksheetPageVersion.worksheetPageId],
      references: [worksheetPage.id],
    }),
    worksheetAnswerKey: one(worksheetAnswerKey),
    worksheetQuestions: many(worksheetPageQuestion),
  })
);

export const insertWorksheetPageVersionSchema =
  createInsertSchema(worksheetPageVersion);
export const selectWorksheetPageVersionSchema =
  createSelectSchema(worksheetPageVersion);

// Worksheet Set Version and Worksheet Version Connection
export const worksheetSetVersionItem = pgTable("worksheet_set_version_item", {
  id: serial("id").primaryKey(),
  worksheetSetVersionId: integer("worksheet_set_version_id").references(
    () => worksheetSetVersion.id
  ),
  worksheetVersionId: integer("worksheet_version_id").references(
    () => worksheetVersion.id
  ),
});

export const worksheetSetVersionItemRelations = relations(
  worksheetSetVersionItem,
  ({ one, many }) => ({
    worksheetSetVersion: one(worksheetSetVersion, {
      fields: [worksheetSetVersionItem.worksheetSetVersionId],
      references: [worksheetSetVersion.id],
    }),
    worksheetVersion: one(worksheetVersion, {
      fields: [worksheetSetVersionItem.worksheetVersionId],
      references: [worksheetVersion.id],
    }),
  })
);

export const insertWorksheetSetVersionItemSchema = createInsertSchema(
  worksheetSetVersionItem
);
export const selectWorksheetSetVersionItemSchema = createSelectSchema(
  worksheetSetVersionItem
);

// Worksheet Answer Key
export const worksheetAnswerKey = pgTable("worksheet_answer_key", {
  id: serial("id").primaryKey(),
  worksheetPageVersionId: integer("worksheet_page_version_id").references(
    () => worksheetPageVersion.id
  ),
});

export const worksheetAnswerKeyRelations = relations(
  worksheetAnswerKey,
  ({ one, many }) => ({
    worksheetPageVersion: one(worksheetPageVersion, {
      fields: [worksheetAnswerKey.worksheetPageVersionId],
      references: [worksheetPageVersion.id],
    }),
    versions: many(worksheetAnswerKeyVersion),
  })
);

export const insertWorksheetAnswerKeySchema =
  createInsertSchema(worksheetAnswerKey);
export const selectWorksheetAnswerKeySchema =
  createSelectSchema(worksheetAnswerKey);

// Worksheet Answer Key Version
export const worksheetAnswerKeyVersion = pgTable(
  "worksheet_answer_key_version",
  {
    id: serial("id").primaryKey(),
    worksheetAnswerKeyId: integer("worksheet_answer_key_id").references(
      () => worksheetAnswerKey.id
    ),
    version: integer("version").notNull(),
    filename: text("filename").notNull(),
  }
);

export const worksheetAnswerKeyVersionRelations = relations(
  worksheetAnswerKeyVersion,
  ({ one }) => ({
    worksheetAnswerKey: one(worksheetAnswerKey, {
      fields: [worksheetAnswerKeyVersion.worksheetAnswerKeyId],
      references: [worksheetAnswerKey.id],
    }),
  })
);

export const insertWorksheetAnswerKeyVersionSchema = createInsertSchema(
  worksheetAnswerKeyVersion
);
export const selectWorksheetAnswerKeyVersionSchema = createSelectSchema(
  worksheetAnswerKeyVersion
);

// Worksheet Set Assignment
export const worksheetSetAssignment = pgTable("worksheet_set_assignment", {
  id: serial("id").primaryKey(),
  garageUserId: integer("garage_user_id").notNull(),
  garageClassroomSessionId: integer("garage_classroom_session_id").notNull(),
  worksheetSetVersionId: integer("worksheet_set_version_id").references(
    () => worksheetSetVersion.id
  ),
  isMarkingComplete: boolean("is_marking_complete").notNull().default(false),
  createdAt: timestamp("created_at", { withTimezone: false, mode: "date" })
    .notNull()
    .defaultNow(),
});

export const worksheetSetAssignmentRelations = relations(
  worksheetSetAssignment,
  ({ one }) => ({
    worksheetSetVersion: one(worksheetSetVersion, {
      fields: [worksheetSetAssignment.worksheetSetVersionId],
      references: [worksheetSetVersion.id],
    }),
  })
);

export const insertWorksheetSetAssignmentSchema = createInsertSchema(
  worksheetSetAssignment
);
export const selectWorksheetSetAssignmentSchema = createSelectSchema(
  worksheetSetAssignment
);

// Worksheet Page Question
export const worksheetPageQuestion = pgTable("worksheet_page_question", {
  id: serial("id").primaryKey(),
  worksheetPageVersionId: integer("worksheet_page_version_id").references(
    () => worksheetPageVersion.id
  ),
  totalPoints: integer("total_points").notNull(),
  content: text("content").notNull(),
});

export const worksheetPageQuestionRelations = relations(
  worksheetPageQuestion,
  ({ one, many }) => ({
    worksheetPageVersion: one(worksheetPageVersion, {
      fields: [worksheetPageQuestion.worksheetPageVersionId],
      references: [worksheetPageVersion.id],
    }),
    markedQuestions: many(markedQuestion),
  })
);

export const revisionQuestionSubunit = pgTable("revision_question_subunit", {
  id: serial("id").primaryKey(),
  worksheetPageQuestionId: integer("worksheet_page_question_id").references(
    () => worksheetPageQuestion.id
  ),
  subunitId: integer("subunit_id").references(() => subunit.id),
});

export const revisionQuestionSubunitRelations = relations(
  revisionQuestionSubunit,
  ({ one }) => ({
    worksheetPageQuestion: one(worksheetPageQuestion, {
      fields: [revisionQuestionSubunit.worksheetPageQuestionId],
      references: [worksheetPageQuestion.id],
    }),
    subunit: one(subunit, {
      fields: [revisionQuestionSubunit.subunitId],
      references: [subunit.id],
    }),
  })
);

export const insertWorksheetPageQuestionSchema = createInsertSchema(
  worksheetPageQuestion
);
export const selectWorksheetPageQuestionSchema = createSelectSchema(
  worksheetPageQuestion
);

// Marked Question
export const markedQuestion = pgTable("marked_question", {
  id: serial("id").primaryKey(),
  worksheetPageQuestionId: integer("worksheet_page_question_id").references(
    () => worksheetPageQuestion.id
  ),
  markingSessionId: integer("marking_session_id").references(
    () => markingSession.id
  ),
  points: integer("points").notNull(),
});

export const markedQuestionRelations = relations(markedQuestion, ({ one }) => ({
  worksheetPageQuestion: one(worksheetPageQuestion, {
    fields: [markedQuestion.worksheetPageQuestionId],
    references: [worksheetPageQuestion.id],
  }),
  markingSession: one(markingSession, {
    fields: [markedQuestion.markingSessionId],
    references: [markingSession.id],
  }),
}));

export const insertMarkedQuestionSchema = createInsertSchema(markedQuestion);
export const selectMarkedQuestionSchema = createSelectSchema(markedQuestion);

// Marking Session
export const markingSession = pgTable("marking_session", {
  id: serial("id").primaryKey(),
  worksheetSetAssignmentId: integer("worksheet_set_assignment_id").references(
    () => worksheetSetAssignment.id
  ),
  createdAt: timestamp("created_at", { withTimezone: false, mode: "date" })
    .notNull()
    .defaultNow(),
});

export const markingSessionRelations = relations(
  markingSession,
  ({ one, many }) => ({
    worksheetSetAssignment: one(worksheetSetAssignment, {
      fields: [markingSession.worksheetSetAssignmentId],
      references: [worksheetSetAssignment.id],
    }),
    markedQuestions: many(markedQuestion),
  })
);

export const insertMarkingSessionSchema = createInsertSchema(markingSession);
export const selectMarkingSessionSchema = createSelectSchema(markingSession);

// Worksheet Page Annotation
export const worksheetPageAnnotation = pgTable("worksheet_page_annotation", {
  id: serial("id").primaryKey(),
  worksheetPageVersionId: integer("worksheet_page_version_id").references(
    () => worksheetPageVersion.id
  ),
  worksheetSetAssignmentId: integer("worksheet_set_assignment_id").references(
    () => worksheetSetAssignment.id
  ),
  garageUserId: integer("garage_user_id").notNull(),
  role: annotationRoleTypeEnum("role").notNull(),
  drawing: json("drawing"),
  textboxes: json("textboxes"),
  createdAt: timestamp("created_at", { withTimezone: false, mode: "date" })
    .notNull()
    .defaultNow(),
  updatedAt: timestamp("updated_at", { withTimezone: false, mode: "date" })
    .notNull()
    .defaultNow(),
});

export const worksheetPageAnnotationRelations = relations(
  worksheetPageAnnotation,
  ({ one }) => ({
    worksheetPageVersion: one(worksheetPageVersion, {
      fields: [worksheetPageAnnotation.worksheetPageVersionId],
      references: [worksheetPageVersion.id],
    }),
    worksheetSetAssignment: one(worksheetSetAssignment, {
      fields: [worksheetPageAnnotation.worksheetSetAssignmentId],
      references: [worksheetSetAssignment.id],
    }),
  })
);

export const insertWorksheetPageAnnotationSchema = createInsertSchema(
  worksheetPageAnnotation
);
export const selectWorksheetPageAnnotationSchema = createSelectSchema(
  worksheetPageAnnotation
);
